import React from 'react'
import logo from '../img/logo.svg'
import * as logoStyles from './Logo.module.scss'

const Logo = ({ responsive }) => {
  const imageClassName = logoStyles.logoImage + ' level-item'
  const touchClassName = responsive ? 'is-hidden-desktop' : 'is-hidden'
  const desktopClassName = responsive ? 'is-hidden-touch' : ''

  return (
    <div className="is-flex is-align-self-center is-align-items-center">
      <img
        src={logo}
        alt="Empowered Care Midwifery"
        className={imageClassName}
      />
      <div className="logo-text is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-size-5-mobile is-size-3-tablet is-size-3-widescreen">
        <div className={touchClassName}>Empowered Care Midwifery</div>
        <div className={desktopClassName}>Empowered Care</div>
        <div className={desktopClassName}>Midwifery</div>
      </div>
    </div>
  )
}

export default Logo
