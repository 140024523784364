import React from 'react'
import { Link } from 'gatsby'

import light_logo from '../img/light_logo.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'

const Footer = () => (
  <footer className="footer has-background-black has-text-white-ter">
    <div className="content has-text-centered has-background-black has-text-white-ter">
      <div className="container has-background-black has-text-white-ter">
        <div className="columns">
          <div className="column is-8">
            <section className="menu">
              <ul className="menu-list">
                <li>
                  <Link to="/" className="navbar-item">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="navbar-item">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/testimonials" className="navbar-item">
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </section>
          </div>
          <div className="column is-4 social">
            <a
              title="facebook"
              href="https://www.facebook.com/Empowered-Care-Midwifery-105171397545829/"
            >
              <img
                src={facebook}
                alt="Facebook"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
            <a
              title="instagram"
              href="https://www.instagram.com/empoweredcaremidwifery/"
            >
              <img
                src={instagram}
                alt="Instagram"
                style={{ width: '1em', height: '1em' }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="content has-text-centered">
      <img
        src={light_logo}
        alt="Empowered Care Midwifery"
        style={{ width: '14em', height: '10em' }}
      />
    </div>
  </footer>
)

export default Footer
