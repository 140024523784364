import React, { useState } from 'react'
import { Link } from 'gatsby'
import Logo from './Logo'

const Navbar = () => {
  const [active, setActive] = useState(false)
  const navBarActiveClass = active ? 'is-active' : ''

  const hamburgerPress = (e) => {
    if (e.key === 'Enter') {
      toggleHamburger()
    }
  }

  const toggleHamburger = () => {
    // toggle the active boolean in the state
    setActive(!active)
  }

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <Logo responsive={true} />
          </Link>
          {/* Hamburger menu */}
          <div
            role="button"
            tabIndex={0}
            className={`navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            onClick={toggleHamburger}
            onKeyPress={hamburgerPress}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${navBarActiveClass}`}>
          <div className="navbar-start" />
          <div className="navbar-end has-text-centered">
            <Link className="navbar-item" to="/services">
              Services
            </Link>
            <Link className="navbar-item" to="/testimonials">
              Testimonials
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
